/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'skip-end-btn': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M6.79 5.093L9.5 7.028V5.5a.5.5 0 011 0v5a.5.5 0 01-1 0V8.972l-2.71 1.935A.5.5 0 016 10.5v-5a.5.5 0 01.79-.407"/><path pid="1" d="M0 4a2 2 0 012-2h12a2 2 0 012 2v8a2 2 0 01-2 2H2a2 2 0 01-2-2zm15 0a1 1 0 00-1-1H2a1 1 0 00-1 1v8a1 1 0 001 1h12a1 1 0 001-1z"/>',
    },
});
